import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"

import "./kpis-with-image.sass"

const KpisWithImage = ({ image, kpis }) => {
  return (
    <div className={"_fp-kpis-with-image"}>
      <div className="_fp-kpis-with-image__background">
        <GatsbyImage image={image}></GatsbyImage>
      </div>

      <div className="_fp-kpis-with-image__kpis">
        {kpis.map((kpi, index) => {
          return (
            <div
              key={`kpis-with-image-kpi-${index}`}
              className="_fp-kpis-with-image__kpis__kpi"
            >
              <div className="_fp-kpis-with-image__kpis__kpi__value">
                {kpi.value}
              </div>
              <div className="_fp-kpis-with-image__kpis__kpi__title">
                {kpi.title}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

KpisWithImage.propTypes = {
  // youtubeId: PropTypes.string.isRequired,
}

export default KpisWithImage
