import PropTypes from "prop-types"
import React from "react"

import "./quote.sass"

const KpisWithImage = ({ quoteTranslatedString, authorTranslatedString }) => {
  return (
    <div className={"_fp-quote"}>
      <div className="_fp-quote__text">{quoteTranslatedString}</div>
      <div className="_fp-quote__author">{authorTranslatedString}</div>
    </div>
  )
}

KpisWithImage.propTypes = {
  quoteTranslatedString: PropTypes.string.isRequired,
  authorTranslatedString: PropTypes.string.isRequired,
}

export default KpisWithImage
