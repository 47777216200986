import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import InitiativeApplication from "../../components/career/initiative-application/initiative-application"
import JobOfferTypes from "../../components/career/job-offer-types/job-offer-types"
import MunzingAsEmployerHeader from "../../components/career/munzing-as-employer-header/munzing-as-employer-header"
import Testimonials from "../../components/career/testimonials/testimonials"
import TextWithImage from "../../components/career/text-with-image/text-with-image"
import WhatAreAdditives from "../../components/career/what-are-additives/what-are-additives"
import TextWithOrWithoutGallery from "../../components/dynamic-page-components/text-with-or-without-gallery/text-with-or-without-gallery"
import { GalleryPictureItem } from "../../components/gallery/model/gallery-item"
import BrandPreview from "../../components/generic-preview/brand-preview/brand-preview"
import GenericPreview from "../../components/generic-preview/generic-preview"
import IconExpansionSection from "../../components/icon-expansion-section/icon-expansion-section"
import KpisWithImage from "../../components/kpis-with-image/kpis-with-image"
import OverviewSlider from "../../components/overview-slider/overview-slider"
import PageHeader from "../../components/page-header/page-header"
import TextWithImageHero from "../../components/page-heros/text-with-image-hero"
import Quote from "../../components/quote/quote"
import RichText from "../../components/rich-text/rich-text"
import SEO from "../../components/seo"
import FullWidthTileContent from "../../components/tile/full-width-tile-content/full-width-tile-content"
import Tile from "../../components/tile/tile"
import TileContentSpacer from "../../components/tile/tile-content-spacer/tile-content-spacer"
import SideNavView from "../../components/views/side-nav-view/side-nav-view"
import useNav from "../../hooks/useNav"
import { removeLocaleFromUri } from "../../i18n/removeLocaleFromUri"
import { useTranslation } from "../../i18n/translate"
import {
  dynamicImageWithGatsbyImage,
  dynamicImageWithPublicUrl,
} from "../../util/dynamic-image"

const SustainabilityPage = ({ data, location }) => {
  const { t } = useTranslation()

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("SUSTAINABILITY-ENVIRONMENT-PAGE_TITLE")}
        descriptionTranslatedString={t(
          "SUSTAINABILITY-ENVIRONMENT-META_DESCRIPTION",
        )}
      />
      <SideNavView
        titleTranslatedString={t("SUSTAINABILITY-ENVIRONMENT-PAGE_TITLE")}
        pathName={location.pathname}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            <Tile
              tileSize={"m"}
              titleTranslatedString={t(
                "SUSTAINABILITY-ENVIRONMENT-TILE1-TITLE",
              )}
            >
              <FullWidthTileContent>
                <KpisWithImage
                  image={dynamicImageWithGatsbyImage(data.tile1_image)}
                  kpis={[
                    {
                      value: t("SUSTAINABILITY-ENVIRONMENT-TILE1-KPI1-VALUE"),
                      title: t("SUSTAINABILITY-ENVIRONMENT-TILE1-KPI1-TITLE"),
                    },
                    {
                      value: t("SUSTAINABILITY-ENVIRONMENT-TILE1-KPI2-VALUE"),
                      title: t("SUSTAINABILITY-ENVIRONMENT-TILE1-KPI2-TITLE"),
                    },
                    {
                      value: t("SUSTAINABILITY-ENVIRONMENT-TILE1-KPI3-VALUE"),
                      title: t("SUSTAINABILITY-ENVIRONMENT-TILE1-KPI3-TITLE"),
                    },
                  ]}
                ></KpisWithImage>
              </FullWidthTileContent>
              <TileContentSpacer />

              <Quote
                quoteTranslatedString={t(
                  "SUSTAINABILITY-ENVIRONMENT-TILE1-QUOTE",
                )}
                authorTranslatedString={t(
                  "SUSTAINABILITY-ENVIRONMENT-TILE1-QUOTE_AUTHOR",
                )}
              ></Quote>

              <TileContentSpacer />
              <RichText
                textTranslationKey={
                  "SUSTAINABILITY-ENVIRONMENT-TILE1-DESCRIPTION"
                }
              ></RichText>
            </Tile>
          </div>

          <div className="_fp-col-12">
            <Tile
              tileSize={"m"}
              titleTranslatedString={t(
                "SUSTAINABILITY-ENVIRONMENT-TILE2-TITLE",
              )}
            >
              <TextWithOrWithoutGallery
                gallerySize={"large"}
                centerText={true}
                expandableText={true}
                gallery={[
                  new GalleryPictureItem({
                    gatsbyFluidImage: dynamicImageWithGatsbyImage(
                      data.tile2_image,
                    ),
                  }),
                ]}
                textTranslationKey={
                  "SUSTAINABILITY-ENVIRONMENT-TILE2-DESCRIPTION"
                }
              ></TextWithOrWithoutGallery>
            </Tile>
          </div>

          <div className="_fp-col-12">
            <Tile
              tileSize={"m"}
              titleTranslatedString={t(
                "SUSTAINABILITY-ENVIRONMENT-TILE3-TITLE",
              )}
            >
              <TextWithOrWithoutGallery
                gallerySize={"large"}
                imageSide={"right"}
                centerText={true}
                gallery={[
                  new GalleryPictureItem({
                    gatsbyFluidImage: dynamicImageWithGatsbyImage(
                      data.tile3_image,
                    ),
                  }),
                ]}
                expandableText={true}
                textTranslationKey={
                  "SUSTAINABILITY-ENVIRONMENT-TILE3-DESCRIPTION"
                }
              ></TextWithOrWithoutGallery>
            </Tile>
          </div>

          <div className="_fp-col-12">
            <Tile
              tileSize={"m"}
              titleTranslatedString={t(
                "SUSTAINABILITY-ENVIRONMENT-TILE4-TITLE",
              )}
            >
              <TextWithOrWithoutGallery
                gallerySize={"large"}
                centerText={true}
                gallery={[
                  new GalleryPictureItem({
                    gatsbyFluidImage: dynamicImageWithGatsbyImage(
                      data.tile4_image,
                    ),
                  }),
                ]}
                expandableText={true}
                textTranslationKey={
                  "SUSTAINABILITY-ENVIRONMENT-TILE4-DESCRIPTION"
                }
              ></TextWithOrWithoutGallery>
            </Tile>
          </div>
        </div>
      </SideNavView>
    </>
  )
}

export default SustainabilityPage

export const query = graphql`
  {
    hero: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_ENVIRONMENT_PAGE_IMAGE" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    tile1_image: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_ENVIRONMENT_TILE1_IMAGE" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    tile2_image: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_ENVIRONMENT_TILE2_IMAGE" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    tile3_image: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_ENVIRONMENT_TILE3_IMAGE" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    tile4_image: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_ENVIRONMENT_TILE4_IMAGE" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
  }
`
